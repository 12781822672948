import React, { useContext } from 'react';
import SeekerContext from '../../context/seekerContext';

const Experience = () => {
  const seeker = useContext(SeekerContext);

  return (
    <ul>
      {seeker[0].Exps.map((val, i) => (
        <li key={i}>
          <div className="list_inner">
            <div className="short">
              <div className="job">
                <h3>{val.JobExpName}</h3>
                <span>{val.CompanyName}</span>
              </div>
              <div className="year">
                <span>
                  {new Date(val.StartDate).toLocaleDateString()}
                  {' - '}
                  {!val.IsCurrent
                    ? new Date(val.EndDate).toLocaleDateString()
                    : 'Present'}
                </span>
              </div>
            </div>
            <div className="text">
              <p>{val.JobDetails}</p>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
