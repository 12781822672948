import React from 'react';

const Footer = () => {
  return (
    <>
      <div className="inner">
        <div className="left">
          <p>
            Designed with love &copy; {new Date().getFullYear()} by{' '}
            <a
              href="http://jobedia.re-view.me/"
              target="_blank"
              rel="noreferrer"
            >
              Jopedia
            </a>
            All right reserved
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
