import React, { useContext } from 'react';
import SeekerContext from '../../context/seekerContext';

const Education = () => {
  const seeker = useContext(SeekerContext);
  return (
    <ul>
      <li>
        <div className="list_inner">
          <div className="short">
            <div className="job">
              <h3>Education Level</h3>
              <span>{seeker[0].EducationLevel}</span>
            </div>
            <div className="year">
              <span>{seeker[0].GraduationYear}</span>
            </div>
          </div>
          <div className="text">
            <p>
              {seeker[0].University} {seeker[0].Faculty}
            </p>
          </div>
        </div>
      </li>
    </ul>
  );
};

export default Education;
