import React, { useContext } from 'react';
import SeekerContext from '../../context/seekerContext';
import { Link } from 'react-router-dom';

const logo = '/img/logo/dark.png';

const HeaderThree = () => {
  const seeker = useContext(SeekerContext);
  return (
    <div className="topbar_inner">
      <div className="logo">
        <Link to="/">
          {' '}
          <img src={logo} alt="brand" />
        </Link>
      </div>
      <div className="right">
        <div className="know_tm_button fixedVersion">
          <a
            href={`http://core.jobedia.org/Resources/Images/${seeker[0].CV}`}
            download
          >
            Download CV
          </a>
        </div>
      </div>

      <div className="know_tm_author_info">
        <div className="info_inner">
          <div className="info">
            <h3>{seeker[0].Name}</h3>
            <span>{seeker[0].JobPostion}</span>
          </div>
          <div className="avatar">
            <div
              className="main"
              data-img-url="/img/hero/3.jpg"
              style={{
                backgroundImage: `url("http://core.jobedia.org/Resources/Images/${seeker[0].ProfilePicture}")`,
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderThree;
