import React from 'react';
import Preview from '../views/Preview';
import HomeOneLight from '../views/all-home-version/HomeOneLight';
import HomeTwoLight from '../views/all-home-version/HomeTwoLight';
import HomeThreeLight from '../views/all-home-version/HomeThreeLight';
import HomeOneDark from '../views/all-home-version/HomeOneDark';
import HomeTwoDark from '../views/all-home-version/HomeTwoDark';
import HomeThreeDark from '../views/all-home-version/HomeThreeDark';
import NotFound from '../views/NotFound';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollTopBehaviour from '../components/ScrollTopBehaviour';

const Routes = () => {
  return (
    <>
      <Router>
        <ScrollTopBehaviour />
        <Switch>
          <Route exact path="/" component={NotFound} />
          <Route exact path="/preview/:id" component={Preview} />
          <Route path="/v1-light/:id" component={HomeOneLight} />
          <Route path="/v2-light/:id" component={HomeTwoLight} />
          <Route path="/v3-light/:id" component={HomeThreeLight} />
          <Route path="/v1-dark/:id" component={HomeOneDark} />
          <Route path="/v2-dark/:id" component={HomeTwoDark} />
          <Route path="/v3-dark/:id" component={HomeThreeDark} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
