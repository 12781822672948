import React, { useContext } from 'react';
import SeekerContext from '../../context/seekerContext';
import { Link } from 'react-router-dom';

const logo = '/img/logo/light.png';

const HeaderFourDark = () => {
  const seeker = useContext(SeekerContext);
  return (
    <div className="topbar_inner">
      <div className="logo">
        <Link to="/">
          {' '}
          <img src={logo} alt="brand" />
        </Link>
      </div>
      <div className="right">
        <div className="know_tm_button ">
          <a
            href={`http://core.jobedia.org/Resources/Images/${seeker[0].CV}`}
            download
          >
            Download CV
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeaderFourDark;
