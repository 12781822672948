import React, { useContext } from 'react';
import SeekerContext from '../../context/seekerContext';

const aboutContentInfo = {
  meta: 'About Me',
  title: 'World leading UI/UX designer',
  subTitle: 'A passionate UI/UX Designer and Web Developer based In NYC, USA',
  description: `Hi! My name is Jessica Parker. I am UI/UX designer, and I'm very passionate and dedicated to my work. With 20 years experience as a professional graphic designer, I have acquired the skills and knowledge necessary to make your project a success.`,
};

const PersonalInfo = () => {
  const seeker = useContext(SeekerContext);
  console.log(seeker[0]);
  return (
    <div className="know_tm_about">
      <div className="left" data-aos="fade-right">
        <div className="left_inner">
          <div className="image">
            <img src="/img/thumbs/35-44.jpg" alt="" />
            <div
              className="main"
              style={{
                backgroundImage: `url("http://core.jobedia.org/Resources/Images/${seeker[0].ProfilePicture}")`,
              }}
            ></div>
          </div>
          {/* End .image */}

          <div className="details">
            <ul>
              <li>
                <h3>Name</h3>
                <span>{seeker[0].Name}</span>
              </li>
              <li>
                <h3>Birthday</h3>
                <span>
                  {new Date(seeker[0].DOB).toLocaleDateString('en-EG')}
                </span>
              </li>
              <li>
                <h3>Mail</h3>
                <span>{seeker[0].Email}</span>
              </li>
              <li>
                <h3>Mobile</h3>
                <span>{seeker[0].Mobile}</span>
              </li>
              <li>
                <h3>Gender</h3>
                <span>{seeker[0].Gender}</span>
              </li>
              <li>
                <h3>Nationality</h3>
                <span>{seeker[0].Nationality}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* End .left */}

      <div className="right" data-aos="fade-right">
        <div className="know_tm_main_title">
          <span>{aboutContentInfo.meta}</span>
          <h3>{seeker[0].JobPostion}</h3>
        </div>
        <div className="bigger_text">
          <p>Years Of Experience: {seeker[0].YearsOfExp}</p>
        </div>
        <div className="text">
          <p>{seeker[0].Bio}</p>
        </div>
        <div className="know_tm_button">
          <a
            href={`http://core.jobedia.org/Resources/Images/${seeker[0].CV}`}
            download
          >
            Download CV
          </a>
        </div>
      </div>
      {/* End .right */}
    </div>
  );
};

export default PersonalInfo;
