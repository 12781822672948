import React, { useContext } from 'react';
import SeekerContext from '../../context/seekerContext';
import Social from '../Social';
import { Link } from 'react-router-dom';

const logo = '/img/logo/light.png';

const Header = () => {
  const seeker = useContext(SeekerContext);

  return (
    <div className="topbar_inner">
      <div className="logo">
        <Link to="/">
          {' '}
          <img src={logo} alt="brand" />
        </Link>
      </div>
      {/* End .logo */}

      <div className="right">
        <div className="social">
          <Social />
        </div>
        {/* End .social */}
        <div className="know_tm_button">
          <a
            href={`http://core.jobedia.org/Resources/Images/${seeker[0].CV}`}
            download
          >
            Download CV
          </a>
        </div>
        {/* End download button */}
      </div>
    </div>
    // End .topbar_inner
  );
};

export default Header;
