import React, { useEffect, useCallback, useState, useContext } from 'react';
import Routes from './router/Routes';
import ScrollToTop from './components/ScrollToTop';
import AOS from 'aos';
import AnimatedCursor from 'react-animated-cursor';
import 'aos/dist/aos.css';
import SeekerContext from './context/seekerContext';

const App = () => {
  const [seeker, setSeeker] = useState(null);
  //const [url, setUrl] = useState('');
  const id = window.location.pathname.split('/');
  const url = `https://core.jobedia.org/api/SeekerWebsite/GetSeekerProfile/${id[2]}`;
  const getSeeker = useCallback(async () => {
    var result = await fetch(url);
    var json = await result.json();
    setSeeker(json);
    //console.log(json);
  }, [url]);

  useEffect(() => {
    getSeeker();
  }, [getSeeker]);

  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);
  return (
    <div className="know_tm_all_wrap">
      <AnimatedCursor
        innerSize={8}
        outerSize={44}
        color="255, 69, 27"
        outerAlpha={0.4}
        innerScale={0.7}
        outerScale={1.2}
      />
      <ScrollToTop />

      {seeker && (
        <SeekerContext.Provider value={seeker}>
          <Routes />
        </SeekerContext.Provider>
      )}
    </div>
  );
};

export default App;
