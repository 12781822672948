import React, { useContext } from 'react';
import SeekerContext from '../../context/seekerContext';

const skillContent = [
  {
    label: 'Time Management',
    percentagePosition: '10%',
    percentageNo: '90%',
  },
  {
    label: 'Effeciency',
    percentagePosition: '30%',
    percentageNo: '70%',
  },
  {
    label: 'Intigrity',
    percentagePosition: '20%',
    percentageNo: '80%',
  },
];

const PersonalSkills = () => {
  const seeker = useContext(SeekerContext);
  return (
    <div className="personal">
      <div className="dodo_progress">
        {seeker[0].Skills.filter((sk) => sk.SkillTypeId !== 1).map((val, i) => (
          <div className="progress_inner" key={i}>
            <span>
              <span className="label">{val.Name}</span>
              <span
                className="number"
                style={{ right: ((5 - val.Rate) / 5) * 100 + '%' }}
              >
                {(val.Rate / 5) * 100}
              </span>
            </span>
            <div className="background">
              <div className="bar">
                <div
                  className="bar_in"
                  style={{ width: (val.Rate / 5) * 100 + '%' }}
                ></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PersonalSkills;
