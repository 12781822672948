import React, { useContext } from 'react';
import SeekerContext from '../../context/seekerContext';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import VisibilitySensor from 'react-visibility-sensor';
import 'react-circular-progressbar/dist/styles.css';

const SoftwareSkills = () => {
  const seeker = useContext(SeekerContext);

  return (
    <div className="software">
      <div className="circular_progress_bar">
        <ul>
          {seeker[0].Skills.filter((sk) => sk.SkillTypeId === 1).map(
            (skill, idx) => (
              <li key={idx}>
                <div className="list_inner">
                  <div style={{ width: '200px' }}>
                    <VisibilitySensor>
                      {({ isVisible }) => {
                        const percentage = isVisible
                          ? (skill.Rate / 5) * 100
                          : 0;
                        return (
                          <CircularProgressbar
                            value={percentage}
                            text={`${percentage}%`}
                            strokeWidth={2.5}
                            styles={buildStyles({
                              textColor: '#E6295B',
                              pathColor: '#E6295B',
                              trailColor: '#eee',
                              textSize: '12',
                            })}
                          />
                        );
                      }}
                    </VisibilitySensor>

                    <div className="title">
                      <h5>{skill.Name}</h5>
                    </div>
                  </div>
                </div>
              </li>
            )
          )}
        </ul>
      </div>
    </div>
  );
};

export default SoftwareSkills;
